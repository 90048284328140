import { Heading } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";
import { routes } from "../../../lib/routes";
import { Button } from "../../Shared/Button/Button";
import { Container } from "../../Shared/Container/Container";
import { ContentRenderer } from "../../Shared/ContentRenderer/ContentRenderer";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { useBuyingService } from "./hooks/useBuyingService";

export interface BuyingServiceProps {
  className?: string;
}

export const BuyingService = (props: BuyingServiceProps) => {
  const { className = "" } = props;

  const {
    leftSideHeading,
    leftSideText,
    rightSideText,
    rightSideTitle,
  } = useBuyingService();

  return (
    <section className={`${className}`} id="kupa">
      <div className="py-16 bg-secondary text-center">
        <Container>
          <SectionHeader
            variant="light"
            className=""
            subtitle="Kúpa nehnuteľnosti"
            title="NÁJDEM TÚ SPRÁVNU NEHNUTEĽNOSŤ PRE VÁS"
            description="Nemáte čas venovať sa hľadaniu tej správnej nehnuteľnosti?"
          />
        </Container>
      </div>
      <Container className="content py-16 bg-white grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-16">
        <div className="left">
          <Heading mb="5" size="md">
            {leftSideHeading}
          </Heading>
          <ContentRenderer source={leftSideText} />
        </div>
        <div className="right flex flex-col justify-between">
          <div className="content">
            <Heading mb="5" size="md">
              {rightSideTitle}
            </Heading>
            <ContentRenderer source={rightSideText} />
          </div>

          <Link to={routes.contact.to} className="mt-8">
            <Button>MÁM ZÁUJEM O KONZULTÁCIU</Button>
          </Link>
        </div>
      </Container>
    </section>
  );
};
