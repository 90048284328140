import { graphql, useStaticQuery } from "gatsby";

export interface ServiceSection {
  rightSideTitle: string;
  rightSideText: string;
  leftSideText: string;
  leftSideHeading: string;
}

export const useSellingService = (): ServiceSection => {
  const {
    strapiServices: { selling },
  } = useStaticQuery(graphql`
    query useSellingService {
      strapiServices {
        selling {
          rightSideTitle
          rightSideText
          leftSideText
          leftSideHeading
        }
      }
    }
  `);

  return selling;
};
