import React from "react";
import { BuyingService } from "../components/Services/BuyingService/BuyingService";
import { SellingService } from "../components/Services/SellingService/SellingService";
import { ServicesHero } from "../components/Services/ServicesHero/ServicesHero";
import { ValuationService } from "../components/Services/ValuationService/ValuationService";
import Layout from "../components/Shared/Layout/Layout";
import { MessageFormSection } from "../components/Shared/MessageFormSection/MessageFormSection";

export interface RealEstateServicesPageProps {
  className?: string;
}

export const RealEstateServicesPage = (props: RealEstateServicesPageProps) => {
  const { className = "" } = props;
  return (
    <Layout className={`${className}`}>
      <ServicesHero />
      <div className="services mt-24">
        <SellingService />
        <BuyingService />
        <ValuationService />
      </div>
      <div className="message-form mt-16">
        <MessageFormSection />
      </div>
    </Layout>
  );
};

export default RealEstateServicesPage;
