import React from "react";
import { AppointmentForm } from "../../Home/AppointmentFormSection/components/AppointmentForm";
import { Container } from "../../Shared/Container/Container";
import { ListItem } from "../../Shared/List/components/ListItem";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";

export interface ValuationServiceProps {
  className?: string;
}

export const ValuationService = (props: ValuationServiceProps) => {
  const { className = "" } = props;
  return (
    <section className={`${className} `} id="ocenenie">
      <div className="py-14 bg-secondary">
        <Container>
          <SectionHeader
            variant="light"
            className="text-center"
            subtitle="Bezplatné ocenenie nehnuteľnosti"
            title="REZERVUJTE SI TERMÍN STRETNUTIA"
            description="Potrebujete zistiť hodnotu vašej nehnuteľnosti a neviete ako?"
          />
        </Container>
        <Container className="content md:space-y-4 space-y-14 mt-14 text-gray-300">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16">
            <div className="left flex items-center">
              <ListItem label="Predaj nehnuteľnosti začína správnym určeným trhovej ceny" />
            </div>
            <div className="right">
              Osobné stretnutie a obhliadka nehnuteľnosti mi poskytne potrebné
              informácie k správnemu určeniu trhovej ceny.
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16">
            <div className="left flex items-center">
              <ListItem label="Pri dedičskom konaní notár vyžaduje ocenenie nehnuteľnosti" />
            </div>
            <div className="right">
              Určenie trhovej hodnoty nehnuteľnosti je potrebné najmä za úcelom
              predaja, či dedičského konania.
            </div>
          </div>
        </Container>
      </div>
      <div className="form bg-section py-16">
        <Container className="">
          <AppointmentForm />
        </Container>
      </div>
    </section>
  );
};
