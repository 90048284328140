import React from "react";
import { Container } from "../../Shared/Container/Container";
import { SectionHeader } from "../../Shared/SectionHeader/SectionHeader";
import { ServicePicker } from "../../Shared/Services/SerivcePicker/SerivcePicker";

export interface ServicesHeroProps {
  className?: string;
}

export const ServicesHero = (props: ServicesHeroProps) => {
  const { className = "" } = props;
  return (
    <section className={`${className} py-16`}>
      <Container>
        <SectionHeader
          className="text-center"
          subtitle="Realitné služby"
          title="INDIVUÁLNE REALITNÉ SLUŽBY NA ÚROVNI"
          description="Poskytujem klientom vysoký štandard služieb"
        />
        <div className="items mt-14">
          <ServicePicker />
        </div>
      </Container>
    </section>
  );
};
