import { graphql, useStaticQuery } from "gatsby";
import { ServiceSection } from "../../SellingService/hooks/useSellingService";

export const useBuyingService = (): ServiceSection => {
  const {
    strapiServices: { buying },
  } = useStaticQuery(graphql`
    query useBuyingService {
      strapiServices {
        buying {
          rightSideTitle
          rightSideText
          leftSideText
          leftSideHeading
        }
      }
    }
  `);

  return buying;
};
